/* Color definitions */
:root {
  --bs-body-font-size: 15px;

  --color-p-700: #629ba3;
  --color-p-600: hsl(188, 38%, 58%);
  --color-p-500: #73c2ce;
  --color-p-200: #9be2e8;
  --color-p-200h: hsl(185, 63%, 80%);
  --color-p-100: hsl(185, 64%, 85%);
  --color-s-700: hsl(40, 56%, 22%);
  --color-s-600: hsl(41, 50%, 27%);
  --color-s-500: hsl(42, 47%, 30%);
  --color-s-200: hsl(38, 30%, 64%);
  --color-s-100: hsl(39, 41%, 77%);

  --text-color-light: #eee;
  --text-color-dark: #222;
}
/* Default colors */
body {
  --text-color: var(--text-color-dark);
  --text-color-on-p: #fff;
  --text-color-on-s: #fff;
  --color-p: var(--color-p-700);
  --color-p-hover: var(--color-p-600);
  --color-s: var(--color-s-700);
  --color-s-hover: var(--color-s-600);
  --bg-color: #fff;
  --bg-color-1: #e6e6e6;
  --bg-color-1-lighter: #f3f3f3;
  --bg-color-2: #d0d0d0;
  --bg-color-disabled: var(--bg-color-2);
  --link-color: var(--color-p-700);
  --link-color-hover: var(--color-p-600);
  --border-color-primary: #d7dee5;
  --border-color-highlight: #e9ecef;
  --color-green: #5eb400;
  --color-red: #d0021b;

  --shadow-inset-bottom-13: inset 0 -1px 0 rgb(0 0 0 / 13%);

  --dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23629ba3%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  --accordion-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23629ba3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
/* Dark theme colors */
body.dark-theme {
  --text-color: var(--text-color-light);
  --text-color-on-p: #000;
  --text-color-on-s: #000;
  --color-p: var(--color-p-200);
  --color-p-hover: var(--color-p-200h);
  --color-s: var(--color-s-200);
  --color-s-hover: var(--color-s-100);
  --bg-color: #121212;
  --bg-color-1: #393939;
  --bg-color-1-lighter: #444;
  --bg-color-2: #5a5a5a;
  --link-color: var(--color-p-200);
  --link-color-hover: var(--color-p-200h);
  --border-color-primary: #5a646e;
  --border-color-highlight: #788797;

  --shadow-inset-bottom-13: inset 0 -1px 0 rgb(255 255 255 / 13%);

  --dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%239be2e8%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  --accordion-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%239be2e8%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

/* Styles for users who prefer dark mode at the OS level */
@media (prefers-color-scheme: dark) {
  /* defaults to dark theme */
  body { 
    --text-color: var(--text-color-light);
    --text-color-on-p: #000;
    --text-color-on-s: #000;
    --color-p: var(--color-p-200);
    --color-p-hover: var(--color-p-200h);
    --color-s: var(--color-s-200);
    --color-s-hover: var(--color-s-100);
    --bg-color: #121212;
    --bg-color-1: #393939;
    --bg-color-1-lighter: #444;
    --bg-color-2: #5a5a5a;
    --link-color: var(--color-p-200);
    --link-color-hover: var(--color-p-200);
    --border-color-primary: #5a646e;
    --border-color-highlight: #788797;

    --shadow-inset-bottom-13: inset 0 -1px 0 rgb(255 255 255 / 13%);

    --dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%239be2e8%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    --accordion-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%239be2e8%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }
  /* Override dark mode with light mode styles if the user decides to swap */
  body.light-theme {
    --text-color: var(--text-color-dark);
    --text-color-on-p: #fff;
    --text-color-on-s: #fff;
    --color-p: var(--color-p-700);
    --color-p-hover: var(--color-p-600);
    --color-s: var(--color-s-700);
    --color-s-hover: var(--color-s-600);
    --bg-color: #fff;
    --bg-color-1: #e6e6e6;
    --bg-color-1-lighter: #f3f3f3;
    --bg-color-2: #d0d0d0;
    --link-color: var(--color-p-700);
    --link-color-hover: var(--color-p-600);
    --border-color-primary: #d7dee5;
    --border-color-highlight: #e9ecef;

    --shadow-inset-bottom-13: inset 0 -1px 0 rgb(0 0 0 / 13%);

    --dropdown-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23629ba3%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    --accordion-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23629ba3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }
}

