.crypto-chart {
  h2 {
    text-align: center;
  }
  .tabs {
    margin-bottom: 10px;
    button {
      margin-right: 5px;
    }
  }
}

.highcharts-container  {

}