.coindata {
  td:nth-child(2), td:nth-child(3) {
    font-weight: 700;
    text-align: right;
  }
}
.coin {
  cursor: pointer;
  &:hover {
    background-color: var(--bg-color-1-lighter);
    box-shadow: 0 0 0.5rem 0.05rem var(--color-p);
    margin: -0.5rem;
    padding: 0.5rem;
  }
}

.coin-logo {
  height: 1.25em;
  margin-right: 0.25em;
  vertical-align: text-top;
}
