.totals {
  background-color: var(--bg-color-1-lighter);
  padding: 0.75rem 0.75rem;
}

.totals [class*='col-'] div {
  font-size: 42px;
  font-weight: 700;
  text-align: right;
}

.top-right {
  position: absolute;
  right: 15px;
  top: 35px;
  button {
    margin-left: 10px;
  }
}
