@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

body {
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color);
  background: var(--bg-color);
}

h1 {
  font-size: 48px;
  margin: 25px 0;
  text-align: center;
  span {
    color: var(--color-p);
    cursor: pointer;

    &:hover, &:hover *, &:active {
      // color: lighten(var(--color-p), 10%);
      color: var(--color-p-hover);
    }
  }
}

h2 {
  font-weight: 600;
  margin: 0.25rem 0;
}

h3 {
  font-size: 1.5rem;
  // margin-bottom: 1rem;
}

h4 {
  font-size: 1.25rem;
}

a {
  color: var(--link-color);
  &:hover, &:focus {
    color: var(--link-color-hover);
  }
}

table {
  width: 100%;
}
tr:nth-child(even) {
  background-color: var(--bg-color-1-lighter);
}
tr:nth-child(odd) {
  background-color: var(--bg-color-1);
}
td {
  padding: 4px 5px;
}

@media (max-width: 1080px) and (orientation: portrait) {
  h1 {
    font-size: 44px;
    margin: 20px 0;
  }
}

.green {
  color: var(--color-green);
}

.red {
  color: var(--color-red);
}

.rotate {
  animation: spin 1s linear infinite;
  display: inline-block;
}
@keyframes spin { 100% { transform:rotate(360deg); }}

.shake {
  animation: shake 1.0s cubic-bezier(.36,.07,.19,.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  opacity: 0.33;
  transition: opacity .5s;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(3px, 0, 0);
  }
}

.dark-switch {
  height: 38px !important;
  width: 69px !important;
}

/* Bootstrap */
.nav-tabs {
  border-bottom-color: var(--border-color-primary);
  .nav-link {
    color: var(--link-color);
    &:hover {
      color: var(--link-color-hover);
      border-color: var(--border-color-highlight) var(--border-color-highlight) var(--border-color-primary);
    }
    &.active {
      color: var(--color-p);
      background-color: var(--bg-color);
      border-color: var(--border-color-primary) var(--border-color-primary) var(--bg-color);
    }
  }
}

.form-control {
  background-color: var(--bg-color-1);
  border-color: var(--border-color-primary);
  color: var(--text-color);
  &:focus {
    background-color: var(--bg-color-1-lighter);
    border-color: var(--border-color-highlight);
    color: var(--text-color);
    box-shadow: 0 0 10px 0.15rem var(--color-p);
  }
  &:invalid {
    border-color: var(--color-red);
    box-shadow: 0 0 10px 0.15rem var(--color-red);
  }
  &:disabled, &[readonly] {
    background-color: var(--bg-color-disabled);
    border-color: var(--bg-color-disabled);
  }
}

.form-select {
  background-color: var(--bg-color-1);
  background-image: var(--dropdown-icon);
  border-color: var(--border-color-primary);
  color: var(--text-color);
  &:focus {
    background-color: var(--bg-color-1-lighter);
    border-color: var(--border-color-highlight);
    box-shadow: 0 0 10px 0.15rem var(--color-p);
  }
}

.accordion-item {
  background-color: transparent;
  border-color: var(--border-color-primary);
}

.accordion-button {
  color: var(--text-color);
  background-color: var(--bg-color);
  &:focus {
    border-color: var(--border-color-highlight);
    box-shadow: 0 0 10px 0.15rem var(--color-p);
  }
  &:not(.collapsed) {
    color: var(--text-color);
    background-color: var(--bg-color);
    box-shadow: var(--shadow-inset-bottom-13);
    &::after {
      background-image: var(--accordion-icon);
    }
  }
  &::after {
    background-image: var(--accordion-icon);
  }
}

.btn {
  [role=img] {
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
  }
}

.btn-primary {
  &, &:focus {
    color: var(--text-color-on-p);
    background-color: var(--color-p);
    border-color: var(--color-p);
  }
  &:hover {
    color: var(--text-color-on-p);
    background-color: var(--color-p-hover);
    border-color: var(--color-p-hover);
  }
}

.btn-outline-primary {
  color: var(--color-p);
  border-color: var(--color-p);
  &:hover {
    color: var(--text-color-on-p);
    background-color: var(--color-p);
    border-color: var(--color-p);
  }
  &:focus {
    box-shadow: 0 0 10px 0.15rem var(--color-p);
  }
}

.btn-secondary {
  &, &:focus {
    color: var(--text-color-on-s);
    background-color: var(--color-s);
    border-color: var(--color-s);
  }
  &:hover {
    color: var(--text-color-on-s);
    background-color: var(--color-s-hover);
    border-color: var(--color-s-hover);
  }
}

.btn-outline-secondary {
  color: var(--color-s);
  border-color: var(--color-s);
  &:hover {
    color: var(--text-color-on-s);
    background-color: var(--color-s);
    border-color: var(--color-s);
  }
  &:focus {
    box-shadow: 0 0 10px 0.15rem var(--color-s);
  }
}

.btn-icon {
  padding: .375rem .5rem;
}

.btn-xs {
  padding: 0 2px;
}
